import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppHeading,
  Step0Comp,
  Step1Comp,
  Step2Comp,
  Step3Comp,
} from "../../../components";
import { networkText, steps } from "../../../utilities";

import {
  addInductionStation,
  getInductionDetails,
  getOrgAction,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import { date } from "yup";

const AddForm = ({ navigation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch(null);
  const location = useLocation();

  const { inductionInfo, organizations, suppliers } = useSelector(
    (state) => state?.inductions
  );

  let step = 0;
  const [stepper, setStepper] = useState(step);
  const [id, SetId] = useState(null);
  const [showData, setShowData] = useState(false);
  const [mpptId, setMpptId] = useState(null);

  useEffect(() => {
    localStorage.removeItem("step0");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");

    getOrganizations();

    const onBoardingId = location?.state?.onbId || null;

    if (onBoardingId) {
      getDetails(onBoardingId);
    } else {
      setShowData(true);
    }
  }, []);

  const getOrganizations = async () => {
    if (navigator.onLine) {
      const body = {
        onSuccess: async (res) => {},
        onFailure: (res) => {},
      };

      dispatch(getOrgAction(body));
    } else {
      toast.error(networkText);
    }
  };

  const onNext = async (values) => {
    if (navigator.onLine) {
      let requestBody = {};

      if (stepper === 0) {
        requestBody = {
          id: id,
          site_id: values?.siteId,
          project_id: values?.projectId,
          gen_site_id: values?.genSiteId,
          client_name: values?.clientName,
          site_name: values?.siteName,
          postal_address: values?.postalName,
          city: values?.city,
          region: values?.region,
          longitude: values?.longitude,
          latitude: values?.latitude,
          site_type: values?.siteType1,
          system_type: values?.siteType2,
          site_category: values?.siteCategory,
          system_size: values?.systemSize,
          site_comissioning_date: values?.siteCommDate,
          site_export_date: values?.siteExportDate,
          is_export_enabled: values?.exportType == "Export-Enable" ? 1 : 0,
        };
      } else if (stepper === 1) {
        requestBody = {
          id: id,
          inverter_commisioning_date: values?.indivInvCommDate,
          structure_type: values?.structureType,
          mount_type: values?.mountType,
          warranty_starts: values?.warrantyStarts,
          oem_panel: values?.oemPanels,
          model_name: values?.modelName,
          panels_type: values?.panelType,
          panel_kind: values?.panelKind,
          p_max: values?.pMax,
          module_efficienncy_stc: values?.moduleEff,
          maximum_system_voltage: values?.maxSysVolt,
          temperature_cofficients_pmax: values?.tempCoeff,
          total_panels: values?.tNoPanel,
          inverter_model: values?.invrModel,
          total_inverters: values?.noOfInv,
          inverter_datasheet: values?.invDatasheet,
          string_mppt: values?.stringMppt,
          provider: values?.provider,
        };
      } else if (stepper === 2) {
        requestBody = {
          id: id,
          inverter_supplier_name: values?.invrSupplName,
          mechnaical_contractor_name: values?.mechContrName,
          electrical_contractor_name: values?.electContrName,
          grid_supplier_name: values?.gridSupplName,
          reference_number_electricity_bill: values?.refElectBill,
          poc: values?.poc,
          poc_number: values?.pocNo,
        };
      } else if (stepper === 3) {
        requestBody = {
          id: id,
          mppt_json: values.mppt_values,
          expected_power: values.expected_power,
          is_submitted: true,
        };
      }

      const body = {
        values: requestBody,
        onSuccess: async (res) => {
          if (res) {
            if (stepper >= 3) {
              navigate("/induction");
            } else {
              setStepper(stepper + 1);
            }

            SetId(res?.data?.id);
          }
        },
        onFailure: (error) => {
          toast.error(error?.data?.message);
        },
      };
      localStorage.setItem(`step${stepper}`, JSON.stringify(requestBody));
      dispatch(addInductionStation(body));
    } else {
      toast.error(networkText);
    }
  };

  const getDetails = (onboardingId) => {
    const requestBody = {
      id: onboardingId,
    };

    const body = {
      values: requestBody,
      onSuccess: async (res) => {
        if (res) {
          SetId(res?.data?.id);
          setShowData(true);
        }
      },
      onFailure: (error) => {
        toast.error(error?.data?.message);
      },
    };

    dispatch(getInductionDetails(body));
  };

  return (
    <Col className="inductionCon">
      <Box sx={{ p: 3, width: "100%" }}>
        <AppHeading title={"Induction"} />

        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={stepper} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    style: {
                      color:
                        stepper > index
                          ? "var(--primary_color)"
                          : stepper === index
                          ? "var(--primary_color)"
                          : "grey",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {stepper === 0 && showData && (
          <Step0Comp
            onClickNext={(val) => onNext(val)}
            data={JSON.parse(localStorage.getItem("step0")) || inductionInfo}
            org={
              organizations &&
              organizations
                .filter((entry) => entry.name !== null)
                .map((entry) => entry.name)
            }
            stepper={stepper}
            setStepper={(val) => setStepper(val)}
          />
        )}
        {stepper === 1 && (
          <Step1Comp
            onClickNext={(val) => onNext(val)}
            stepper={stepper}
            setStepper={(val) => setStepper(val)}
            data={JSON.parse(localStorage.getItem("step1")) || inductionInfo}
          />
        )}
        {stepper === 2 && (
          <Step2Comp
            onClickNext={(val) => onNext(val)}
            stepper={stepper}
            setStepper={(val) => setStepper(val)}
            data={JSON.parse(localStorage.getItem("step2")) || inductionInfo}
            supp={suppliers && suppliers?.map((entry) => entry.name)}
          />
        )}
        {stepper === 3 && (
          <Step3Comp
            onClickSubmit={(val) => onNext(val)}
            stepper={stepper}
            setStepper={(val) => setStepper(val)}
            isUpdated={mpptId != null ? true : false}
            setMpptId={(id) => {
              setMpptId(id);
            }}
            data={JSON.parse(localStorage.getItem("step3")) || inductionInfo}
          />
        )}
      </Box>
    </Col>
  );
};

export default AddForm;
